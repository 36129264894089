import React from 'react';
import styles from './styles/Avartar.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function LayerCont({ image, isNew,isModal, setisModal, handleClick}) {
    console.log(isModal);
        
        return (
        <div className={styles.styleModal}>
            <LazyLoadImage className={styles['layer-modal']} src={image} alt='' effect="blur" visibleByDefault={false} />   
            <button className={styles['layer-modal__button']} onClick={handleClick}>Close</button>
        </div>
    );
};
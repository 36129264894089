import React, { useState } from 'react'

export default function AppMentor() {
    const [person, setPerson] = useState({
        name: 'Peter',
        title: 'Software Engineer',
        mentors: [{
            name: 'Bob',
            title: 'Senior Software Engineer'
        },
        {
            name: 'Bob2',
            title: 'Senior Software Engineer2'
        }]
    });
  return (  
    <div>
        <h1>{person.name}는 {person.title}</h1>
        <p>{person.name}의 멘토는 
            <ul>
                {person.mentors.map( (mentors, index) => (
                    <li key={index}>{mentors.name} ({mentors.title})</li>
                    ))}
            </ul>
        </p>
        <button onClick={()=>{
            const name = prompt(`what's your name?`);
            setPerson( (prev) => ({...prev, mentor: {...person.mentor, name}}) )
        }}>멘토 이름 바꾸기
        </button>
        <button onClick={()=>{
            const title = prompt(`what's your title?`);
            setPerson( (prev) => ({...prev, mentor: {...person.mentor,title}}) )
        }}>멘토 타이틀 바꾸기
        </button>

    </div>
  )
}

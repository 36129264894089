import React, { useState, useEffect } from 'react';
import './App.css';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Profile from './components/Profile';
// import Avartar from './components/Avartar';
import axios from 'axios'

export default function AppProfile() {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get ('https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=25&q=LESSERAFIM&key=AIzaSyDmcIaAUDKLOwqE8sYUPfOFnWr_l67E1xc');
      const data = (res) => res.data.items;
      

      setList( data(response) );
  
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    console.log(e);
    alert('clicked-bttn');
  };

  return (
    <>
      {list.length > 0 ? (
        <>
          {list.map((item, index) => (
            /* <Profile image={item.download_url} name={item.author} title={item.id} isAva key={index} isNew={item.width} /> */
            <Profile image={item.snippet.thumbnails.high.url} name={item.snippet.channelTitle} title={item.snippet.title} />
          ))}
        </>
      ) : (
      <div>No data available</div>
      )}
      <button onClick={handleClick}>button</button>
    </>
  );
}
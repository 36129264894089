import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Video from './pages/Video';
import NotFount from './pages/NotFount';
import Root from './pages/Root';
import TodoList from './components/TodoList/TodoList';
import VideoDetail from './pages/VideoDetail';
import AppProfile from './AppProfile';
import AppCounter from './AppCounter';
import AppXY from './components/AppXY/AppXY';
import AppMentor from './components/AppMentor/AppMentor';

const router = createBrowserRouter([
  {
    path: '/',
    element : <Root />,
    children : [{index:true,element:<Home />},
                {path: '/video',element:<Video />},
                {path: '/video/:videoId',element:<VideoDetail/>},
                {path: '/todo',element:<TodoList/>},
                {path: '/profile',element:<AppProfile/>},
                {path: '/counter',element:<AppCounter/> },
                {path: '/AppXY',element:<AppXY />},
                {path: '/AppMentor',element:<AppMentor/>}],
    errorElement : <div>Not Found</div>
  },
  {
    path: '/video',
    element : <Video />
  },
  {
    path: '/todo',
    element : <TodoList />
  },
  {
    path: '/profile',
    element : <AppProfile />
  },
  {
    path: '/counter',
    element : <AppCounter />
  },
  {
    path: '/NotFound',
    element : <NotFount />
  },
  {
    path: '/AppXY',
    element : <AppXY />
  },
  {
    path: '/AppMentor',
    element : <AppMentor />
  }
]);

export default function App() {
  return (
    <RouterProvider router={router} />
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {gsap} from 'gsap'

export default function Video() {
  const [text, setText] = useState('');
  const navigate = useNavigate();
  const handleChange = (e) => {
    setText(e.target.value)

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setText('');
    navigate(`/video/${text}`);//이렇게 하면 video/1이라는 url로 이동한다.
  }
  
  useEffect(() => {
    const ani = gsap.to(".red", {x: 500, duration: 2, ease: "none", yoyo: true, repeat: -1});
    ani.pause()
    // ani.resume()
    console.log('first')
    // alert('first')
    
    return () => {
      // ani.pause()
      ani.resume()
      // alert('return')
      console.log('first-return')
    }
  }, [])
  
  return (
    <div>Video
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder='video id : ' value={text} onChange={handleChange} />
      </form>
      <div className="red">red</div>
    </div>
  )
}

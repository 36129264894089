import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LayerCont from './LayerCont';
import styles from './styles/Avartar.module.css';

export default function Avartar({ image, isNew }) {
    const [isModal, setisModal] = useState(false);
    
    const handleClick = () => {
        setisModal(!isModal);
    }

    return (
    <>
        <div className='box'>
            <button className={styles.button} onClick={handleClick}>
                <LazyLoadImage className='photo' src={image} alt='' effect="blur" visibleByDefault={false} />
            </button>
            {isNew && <span className='label'>New</span>}
            
        </div>
        {isModal && <LayerCont image={image} isModal setisModal={setisModal} handleClick={handleClick}/>}
    </>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <nav>
        <Link to='/'>Home</Link>
        <Link to='/video'>Video</Link>
        <Link to='/todo'>TodoList</Link>
        <Link to='/profile'>Profile</Link>
        <Link to='/counter'>Counter</Link>
        <Link to='/AppXY'>AppXY</Link>
        <Link to='/AppMentor'>AppMentor</Link>
    </nav>
  )
}
